<template>
  <v-container fluid>
    <create-bundle />
  </v-container>
</template>

<script>
import CreateBundle from "../components/CreateBundle";

export default {
  name: "HomeView",

  components: {
    CreateBundle,
  },
};
</script>
