<template>
  <v-container>
    <v-row class="text-center" dense>
      <v-spacer></v-spacer>
      <v-col xs="12" sm="12" md="10" lg="6" xl="6">
        <v-text-field
          v-model="collectionName"
          label="Name your collection"
          outlined
        ></v-text-field>
      </v-col>
      <v-col xs="12" sm="12" md="2" lg="2" xl="2">
        <v-btn
          x-large
          block  
          :disabled="!validCollectionName"
          color="success"
          @click="createCollection"
        >
          Submit
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "create-bundle",
  watch: {
    collectionName(newCollectionName){
      if(newCollectionName.length > 0){
        this.validCollectionName = true;
      } else {
        this.validCollectionName = false;
      }
    }
  },
  methods: {
    createCollection() {
      const collectionUrl = `/collection/${this.collectionName}`;
      this.$router.push(collectionUrl);
    }
  },
  data: () => ({
    validCollectionName: false,
    collectionName: "",
  }),
};
</script>
